


















































import { Component, Mixins } from 'vue-property-decorator'

import { defaultShopTypeOfSelectedTariff } from 'piramis-base-components/src/components/ServicesCart/logic/helpers'
import { Getaway, SelectedShop, Shop, Tariff } from 'piramis-base-components/src/components/ServicesCart/logic/types'
import { Payment as PaymentData } from 'piramis-base-components/src/components/ServicesCart/logic/types'
import { PaymentTargetConfig } from 'piramis-base-components/src/plugins/AdminTemplate/includes/types/Config'
import { default as PaymentApi } from 'piramis-base-components/src/plugins/AdminTemplate/includes/logic/Payment'
import { UseFields } from "piramis-base-components/src/components/Pi"
import MakePayment from 'piramis-base-components/src/components/ServicesCart/components/MakePayment.vue'
import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'

import { errorNotification } from '@/includes/NotificationService'

enum PaymentTarget {
  ChatLicense = 'chatLicense',
  Ad = 'ad',
}

@Component({
  'components': {
    MakePayment,
    CenteredColumnLayout
  },
})
export default class Payment extends Mixins(UseFields) {
  paymentTypes: Array<PaymentTarget> = [ PaymentTarget.ChatLicense, PaymentTarget.Ad ]

  tariff: Tariff | null = null

  get selectedShop(): SelectedShop | null {
    return defaultShopTypeOfSelectedTariff(this.tariff!.shops as { [key in Shop]: Array<Getaway> })
  }

  async generatedPayment(payment: PaymentData): Promise<void> {
    try {
      this.$baseTemplate.loader.open()
      await new PaymentApi(
        this.payMethodsBody().target_id,
        this.payMethodsBody().target_type,
        payment.tariff.name,
        payment.getaway.code,
        payment.tariff.monthCount,
        payment.requiredFields
      ).createPayment()
    } catch (error) {
      errorNotification(error)
    } finally {
      this.$baseTemplate.loader.close()
    }
  }

  payMethodsBody(): PaymentTargetConfig {
    const type = this.$route.params.type
    const key = this.$route.params.key

    if (type === PaymentTarget.ChatLicense && key) {
      return { target_id: { chat_key: key }, target_type: 'chatdonate' }
    } else if (type === PaymentTarget.Ad && key) {
      return { target_id: { invoice_key: key }, target_type: 'adinvoice' }
    } else {
      throw Error(`Unknown target type: got ${ type }`)
    }
  }

  async created(): Promise<void> {
    if(!this.paymentTypes.includes(this.$route.params.type as PaymentTarget)) {
      (this as any).$replaceRoute({ name: 'error_404' })
    }

    try {
      this.$baseTemplate.loader.open()

      const { data } = await PaymentApi.getPayMethods(this.payMethodsBody())
      this.tariff = data.tariffs[0]
    } catch (error) {
      console.error(error)
      errorNotification(error)
    } finally {
      this.$baseTemplate.loader.close()
    }
  }
}
